import { clsx } from 'clsx';
import { ArrowLink } from '~/components/arrow-button.tsx';
import { Grid } from '~/components/grid.tsx';
import { H2, H4 } from '~/components/typography.tsx';

interface HeaderSectionProps {
  ctaUrl?: string;
  cta?: string;
  as?: React.ElementType;
  title: string;
  subTitle: string;
  className?: string;
}

function HeaderSection({ ctaUrl, cta, title, subTitle, className, as }: HeaderSectionProps) {
  return (
    <Grid as={as}>
      <div
        className={clsx(
          'col-span-full flex flex-col space-y-10 lg:flex-row lg:items-end lg:justify-between lg:space-y-0',
          className
        )}
      >
        <div className="space-y-2 lg:space-y-0">
          <H2 className="text-center md:text-left">{title}</H2>
          <H4 variant="secondary" className="text-center md:text-left" as="p">
            {subTitle}
          </H4>
        </div>

        {cta && ctaUrl ? (
          <ArrowLink to={ctaUrl} className="hidden md:block" direction="right">
            {cta}
          </ArrowLink>
        ) : null}
      </div>
    </Grid>
  );
}

export { HeaderSection };
